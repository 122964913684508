<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
        >
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.equipos.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.sucursales.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.sucursales.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.sucursal_id"
                        :placeholder="$t('app.sucursales.single')"
                        :items="sucursales"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                      ></v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.name') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.name')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.nombre"
                        :placeholder="$t('app.headers.name')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.logo') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <base-file-input
                      :placeholder="$t('app.headers.logo')"
                      icon="mdi-image"
                      accept="image/*"
                      @change="onChangeControl"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.dt') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.dt')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.director"
                        :placeholder="$t('app.headers.dt')"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="jugadores.length > 0">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('app.jugadores.name') }}

                    <v-btn
                      small
                      outlined
                      @click="item.jugadores.push({jugador_id: null, posicion_id: null, capitan: false, is_active: false})"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>

                      {{ $t('app.buttons.add') }}
                    </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row
                      v-for="(jugador, index) in item.jugadores"
                      :key="`jugador-${index}`"
                    >
                      <v-col
                        class="d-flex flex-grow-1 flex-shrink-0"
                      >
                        <v-autocomplete
                          :disabled="jugadores.length === 0"
                          v-model="item.jugadores[index].jugador_id"
                          :placeholder="$t('app.jugadores.name')"
                          :items="jugadores"
                          item-text="persona.fullname"
                          item-value="id"
                        >
                          <template v-slot:item="data">
                            <v-list-item-avatar>
                              <img :src="data.item.persona.fotografia">
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title v-text="data.item.persona.fullname"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        class="d-flex flex-grow-1 flex-shrink-0"
                      >
                        <v-autocomplete
                          :items="posiciones"
                          item-text="nombre"
                          item-value="id"
                          :placeholder="$t('app.posiciones.single')"
                          v-model="item.jugadores[index].posicion_id"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        class="d-flex flex-shrink-1 flex-grow-0"
                      >
                        <v-switch
                          class="pl-5"
                          v-model="item.jugadores[index].capitan"
                          :placeholder="$t('app.headers.captain')"
                          :label="$t('app.headers.captain') + ': ' + (item.jugadores[index].capitan ? $t('app.general.yes') : $t('app.general.no'))"
                        />
                      </v-col>
                      <v-col
                        class="d-flex flex-shrink-1 flex-grow-0"
                      >
                        <v-switch
                          class="pl-5"
                          v-model="item.jugadores[index].is_active"
                          :placeholder="$t('app.general.active')"
                          :label="$t('app.general.active') + ': ' + (item.jugadores[index].is_active ? $t('app.general.yes') : $t('app.general.no'))"
                        />
                      </v-col>
                      <v-col
                        class="d-flex flex-shrink-1 flex-grow-0"
                      >
                        <v-btn
                          icon
                          @click="item.jugadores.splice(index, 1)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        name: "EquiposAdd",
      data () {
        return {
          dialog: false,
          item: {
            nombre: '',
            sucursal_id: null,
            logo: false,
            jugadores: [],
            director: ''
          },
          sucursales: [],
          datePickerModalStart: false,
          datePickerModalEnd: false,
          jugadores: [],
          posiciones: [],
        }
      },
      mounted() {
        EventBus.$on('equipos-add', async () => {
          this.toggleLoader()
          this.sucursales = await this.getBranches()
          this.jugadores = await this.getJugadores()
          this.posiciones = await this.getPosiciones()

          this.toggleLoader()
          this.dialog = true
        })
      },
      methods: {
        onChangeControl (files) {
          if (files && files.length > 0) {
            this.item.logo = files[0]
          }
        },
          async saveItem () {
            this.$refs.observer.validate()
              .then(async success => {
                if (!success) return

                this.toggleLoader()

                let formData = new FormData()
                formData.append('nombre', this.item.nombre)
                formData.append('sucursal_id', this.item.sucursal_id)
                formData.append('director', this.item.director)

                if (this.item.logo) {
                  formData.append('logo', this.item.logo)
                }

                for (let index in this.item.jugadores) {
                  let player = this.item.jugadores[index]
                  formData.append(`jugadores[${index}][jugador_id]`, player.jugador_id)
                  formData.append(`jugadores[${index}][posicion_id]`, player.posicion_id)
                  formData.append(`jugadores[${index}][capitan]`, player.capitan)
                  formData.append(`jugadores[${index}][is_active]`, player.is_active)
                }

                await this.$http.post(route('v1/team'), formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                })
                .then(response => {
                  if (response.body.code === 200) {

                    this.item = {
                      nombre: '',
                      sucursal_id: null,
                      logo: false,
                      jugadores: [],
                      director: ''
                    }

                    requestAnimationFrame(() => {
                      this.$refs.observer.reset()
                    })

                    this.dialog = false
                    let equipo = false
                    if (response.body.data.length > 0) equipo = response.body.data[0]
                    EventBus.$emit('equipos-add-to-tournament-question', equipo)
                  } else {
                    this.processError(response)
                  }
                }, error => {
                  this.processError(error)
                })

                this.toggleLoader()
              })
          },
      }
    }
</script>

<style scoped>

</style>
